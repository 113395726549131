<template>
  <div class="parent-voucher">

    <div class="voucher-trigger" v-if="selected">
      <font-awesome-icon  :icon="['far', 'times']" fixed-width />
    </div>

    <article class="voucher" :class="{'selectable' : opac && !selected, 'selected' : selected}">
        <section class="date">
          <time>
            <span v-if="data.AmountType == 'FIXED'"><div style="display: inline;font-size: 17px;">¥</div>{{ data.Amount }}</span>
            <span v-else>{{ data.Amount }}<div style="display: inline;font-size: 17px;">%</div></span>
            <span>{{ $t.getTranslation('LBL_COUPON_LIST_LABEL') }}</span>
          </time>
        </section>
        <section class="voucher-cont">
 
          <h3>{{ $t.getTranslation(data.AmountType == 'FIXED' ? 'LBL_COUPON_AMOUNT_PRICE_OFF' : 'LBL_COUPON_AMOUNT_PRICE_OFF_PERC').replace('{AMOUNT}', data.Amount) }}</h3>
          <div class="even-date">
           <i class="fa fa-calendar"></i>
           <time>
             <span>{{ (data.MinOrderAmount) ? $t.getTranslation(data.AmountType == 'FIXED' ? 'LBL_COUPON_AMOUNT_OFF' : 'LBL_COUPON_AMOUNT_OFF_PERC').replace('{BUY}', data.MinOrderAmount).replace('{AMOUNT}', data.Amount) : $t.getTranslation('LBL_NO_THRESHOLD') }}</span>
             <span>{{ $h.formatDate(data.StartDate) }} - {{ $h.formatDate(data.EndDate) }}</span>

             <div v-if="data?.RedeemableAmount || data?.Balance" style="margin-top:10px;border-top:1px solid rgba(255,255,255,0.5);padding-top:10px;">
               <span v-if="data?.RedeemableAmount">{{ $t.getTranslation('LBL_TOTAL') }}: ¥{{ data.RedeemableAmount }}</span>
               <span v-if="data?.Used">{{ $t.getTranslation('LBL_USED') }}: ¥{{ data.Used }}</span>
               <span v-if="data?.Balance">{{ $t.getTranslation('LBL_AVAILABLE') }}: ¥{{ data.Balance }}</span>
            
             </div>
             
           </time>
          </div>
        </section>
    </article>
        
  </div>
</template>

<script>
import { defineComponent, inject, toRefs } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "VoucherCardComponent",
  components: {},
  props: {
    data: Object,
    opac : {
      type : Boolean,
      default : false
    },
    selected : {
      type : Boolean,
      default : false
    }
  },
  setup(props) {
    const store = useStore();

    return {store};
  },
});
</script>

<style scoped>
.parent-voucher{
  overflow:hidden;
  cursor: pointer;
  position: relative;
}

.voucher
{
  display: block;
  width: 100%;
  background-color: #0c4d8f;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-radius: 4px;
  position: relative;
}

.date
{
  display: table-cell;
  width: 130px;
  position: relative;
  text-align: center;
  border-right: 2px dashed #fff;
}

.date:before,
.date:after
{
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  top: -10px ;
  right: -10px;
  z-index: 1;
  border-radius: 50%
}

.date:after
{
  top: auto;
  bottom: -10px
}

.date time
{
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.date time span{display: block}

.date time span:first-child
{
  font-weight: 600;
  font-size: 200%
}

.date time span:last-child
{
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  margin-top: -10px
}

.voucher-cont
{
  display: table-cell;
  width: calc(100% - 130px);
  font-size: 85%;
  padding: 35px 10px 35px 50px
}

.voucher-cont h3
{
  font-size: 130%
}

.voucher-cont > div
{
  display: table-row
}

.voucher-cont .even-date i,
.voucher-cont .even-info i,
.voucher-cont .even-date time,
.voucher-cont .even-info p
{
  display: table-cell
}

.voucher-cont .even-date i,
.voucher-cont .even-info i
{
  padding: 5% 5% 0 0
}

.voucher-cont .even-info p
{
  padding: 30px 50px 0 0
}

.voucher-cont .even-date time span
{
  display: block
}

.selectable{
 background-color: #a3d0ff80;
 color: #0c4d8f;
}

.voucher-trigger{
      position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    z-index: 999;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
</style>
